
/* COMMON */

body {
    font-family: "Averia Serif Libre", sans-serif;
    background: rgba(100,100,100,.1);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.btn {
    background: white;
}

.list-group {
    /* border-radius: 5px;*/
    /* border: 1px solid rgba(100,100,100,.5);  */
}

.list-group a {
    max-height: 48px;
    overflow: hidden;
    background: none;
}


/* MAIN CONTAINER */

.main-container {
    background: white;
    display: grid;
    grid-template-columns: 300px calc(100vw - 300px);
    transition: all 300ms;
    overflow-x: hidden;
    max-width: 100%;
}

.side-min {
    grid-template-columns: 60px calc(100vw - 60px);
}


/* SIDEBAR */

.aside {
    height: 100vh;
    position: relative;
    
}

.sidebar {
    background: rgba(100,100,100,.1);
    position: fixed;
    height: 100vh;
    width: 290px;   
    padding: 50px 5px 5px 5px; 
    transition: all 300ms;
    box-shadow: 3px 3px 3px;
}

.sidebar-min {
    width: 50px; 
}

.header {
    position: absolute;
    left: 20px;
    top: 5px;
    font-size: 2em;
    line-height: 40px;
    text-transform: capitalize;
}

.header-min {
    display: none;
}

.sidebar-main-btn {
    position: absolute;
    right: 5px;
    top: 5px;
}

.sidebar-content {
    width: 100%;
    height: 100%;
    position: relative;
}

.sidebar-content-min {
    display: none;
}

.sidebar-bottom {    
    position: absolute;
    bottom: 5px;
    width: 97%;
}

.sidebar-bottom img {
    max-width: 25px;
    margin-right: 5px;
}

.sidebar-bottom span {
    display: inline;
}

.sidebar-bottom-min {
    width: 80%;
}

.sidebar-bottom-min span {
    display: none;
}

.sidebar-bottom-min a {
    padding-left: 5px;
    padding-right: 5px;
}


/* MAIN WINDOW */

.main {
    position: relative;
    padding: 50px 20px 0 10px;
}

.main-content {
    /* padding: 0 10px 0 0; */
}

.main-content table {
    background: white;
    border-radius: 5px;
    box-shadow: 3px 3px 3px;
    border: 1px solid rgba(100,100,100,.5);
    width: 100%;    
}

.main-content table thead {
    text-transform: capitalize;
}

.main-content table td {
    max-width: 400px;
    overflow: hidden;
}

.main-content .btn-group {
    margin-bottom: 5px;
}


/* LOGIN PAGE */

.login-container {
    box-shadow: 3px 3px 3px 3px;
    border-radius: 5px;
    padding: 20px;
    width: 30%;
    margin: 15% 35% 20% 35%;
    background: white;
}

.login-container input {
    border: 1px solid rgba(100,100,100,.5);
    font-size: 1.1em;
}


/* MODAL */

.modal {
    display: block;
    background: rgba(0,0,0,.7);
}

.modal .error {
    color: red;
    font-size: 1.2em;
    margin: 10px auto;
}

.modal .success {
    color: green;
    font-size: 1.2em;
    margin: 10px auto;
}

.modal .disable {
    display: disable;
}